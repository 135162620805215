.footer-root {
  background: #4c536e;
}
.footer-root .footer-top {
  padding: 48px 0;
  height: 290px;
}
.footer-root .footer-item .footer-company__logo {
  display: block;
  width: 104px;
  height: 22px;
}
.footer-root .footer-item dt {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 26px;
  padding-bottom: 16px;
}
.footer-root .footer-item dl,
.footer-root .footer-item dt,
.footer-root .footer-item dd {
  margin-bottom: 0;
}
.footer-root .footer-item dd {
  margin-top: 16px;
  height: 22px;
}
.footer-root .footer-item .footer-item__contact-dd {
  height: auto;
}
.footer-root .footer-item .footer-item__contact-title {
  padding-left: 32px;
}
.footer-root .footer-item dd a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
}
.footer-root .footer-item dd:hover a {
  text-decoration: underline;
}
.footer-root .footer-item dd .footer-item__contact-info {
  display: flex;
  max-width: 300px;
  align-items: flex-start;
}
.footer-root .footer-item dd .footer-item__contact-info i {
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  padding-top: 4px;
  background-color: transparent;
}
.footer-root .footer-item dd .footer-item__contact-info span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
}
.footer-root .footer-item .wechat-title {
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
}
.footer-root .footer-item .footer-item__wechat-qr {
  padding-top: 14px;
}
.footer-root .footer-item .footer-item__wechat-qr > img {
  display: block;
  width: 98px;
  height: 98px;
  border-radius: 8px;
  margin-bottom: 12px;
}
.footer-root .footer-item__wechat-qr .footer-item__contact-list {
  display: inline-flex;
}
.footer-root .footer-item__wechat-qr .footer-item__contact-list i {
  display: block;
  width: 24px;
  height: 24px;
  background-color: transparent;
}
/* 底部版权 */
.footer-root .footer-bottom {
  line-height: 48px;
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
}
.footer-root .footer-bottom span {
  padding-right: 32px;
}
