.contact-us__page {
  background: #edeff2;
}
.contact-us__page > .container {
  padding: 120px 0 32px;
}
.contact-us__page .container .contact-us__page-title {
  font-size: 48px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 56px;
  text-align: center;
}
.contact-us__page .contact-us__list p {
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
.contact-us__page .contact-us__list p:last-child {
  margin-right: 0;
}
.contact-us__page .contact-us__ways {
  margin-top: 52px;
}
.contact-us__page .contact-us__form {
  width: 702px;
  flex-shrink: 0;
  height: 362px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 3px 15px 33px 0px rgba(0, 0, 0, 0.06);
  padding: 32px;
}
/* 修改内容 */
.contact-us__page .contact-us__form .title {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 40px;
}
.contact-us__page .contact-us__form .ways_list > div {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.contact-us__page .contact-us__form .ways_list img {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
.contact-us__page .contact-us__form .ways_list p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}
.contact-us__page .contact-us__form .official-account {
  display: flex;
  align-items: center;
  margin-top: 28px;
}
.contact-us__page .contact-us__form .official-account img {
  width: 98px;
  height: 98px;
}
.contact-us__page .contact-us__form .official-account p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-left: 32px;
}

/* 修改内容样式结束 */

/* .contact-us__page .contact-us__form input,
.contact-us__page .contact-us__form textarea {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 8px 16px;
}
.contact-us__page .contact-us__form input {
  width: 301px;
  height: 40px;
  margin-bottom: 32px;
}
.contact-us__page .contact-us__form textarea {
  width: 100%;
  height: 86px;
  resize: none;
}
.contact-us__page .contact-us__form form > div {
  text-align: center;
  margin-top: 32px;
}
.contact-us__page .contact-us__form button {
  display: inline-block;
  outline: none;
  padding: 0 16px;
  background: #377dff;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  color: #ffffff;
  line-height: 36px;
}
.contact-us__page .contact-us__form input:nth-child(even) {
  margin-left: 36px;
} */

.contact-us__page .contact-us__map {
  margin-left: 32px;
  width: 704px;
  flex-shrink: 0;
  height: 362px;
  border-radius: 16px;
}
.baiDu {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
@media screen and (max-width: 1500px) {
  .contact-us__page > .container {
      padding: 132px 0 24px;
  }
  .contact-us__page .contact-us__form input {
    width: 262px;
    height: 40px;
    margin-bottom: 16px;
  }
  .contact-us__page .contact-us__form {
    width: 588px;
    flex-shrink: 0;
    height: 302px;
    padding: 24px;
  }
  .contact-us__page .contact-us__form .title {
    font-size: 20px;
    line-height: 28px;
  }
  .contact-us__page .contact-us__form .ways_list > div {
    margin-top: 16px;
  }
  .contact-us__page .contact-us__form .ways_list p {
    font-size: 14px;
    line-height: 22px;
  }
  .contact-us__page .contact-us__form .official-account {
    margin-top: 14px;
  }
  .contact-us__page .contact-us__form .official-account p {
    margin-left: 8px;
  }
  /* .contact-us__page .contact-us__form input:nth-child(even) {
    margin-left: 16px;
  }
  .contact-us__page .contact-us__form form > div {
    margin-top: 20px;
  } */
  .contact-us__page .contact-us__map {
    width: 588px;
    flex-shrink: 0;
    height: 302px;
    margin-left: 0;
  }
}
/* 移动端样式 */
.contact-us.mobile {
  padding-top: 2.67rem;
  padding-bottom: 0.64rem;
  background: #f9fafc;
}
.contact-us.mobile .contact-us__title {
  font-size: 0.64rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
}
/* 移动端修改样式 */
.contact-us.mobile .contact-us__title {
  margin-bottom: 0.64rem;
}
.contact-us.mobile .contact-us__form {
  width: 9.15rem;
  height: 9.71rem;
  background: #ffffff;
  border-radius: 0.21rem;
  box-shadow: 0.08rem 0.4rem 0.88rem 0rem rgba(0, 0, 0, 0.06);
  padding: 0.43rem;
}
.contact-us.mobile .contact-us__ways > div {
  display: flex;
  align-items: center;
  margin-top: 0.43rem;
}
.contact-us.mobile .contact-us__ways > div:first-child {
  margin-top: 0;
}
.contact-us.mobile .contact-us__ways img {
  display: block;
  flex-shrink: 0;
  width: 0.53rem;
  height: 0.53rem;
  margin-right: 0.21rem;
}
.contact-us.mobile .contact-us__ways p {
  font-size: 0.37rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
}
.contact-us.mobile .official-account {
  text-align: center;
}
.contact-us.mobile .official-account img {
  width: 2.61rem;
  height: 2.61rem;
  margin: 0.85rem 0 0.43rem;
}
.contact-us.mobile .official-account p {
  font-size: 0.37rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  text-align: left;
}

/* 移动端修改样式结束 */

/* .contact-us.mobile .contact-us__ways {
  padding: 0 0.85rem;
  margin-top: 0.64rem;
}
.contact-us.mobile .contact-us__ways div {
  font-size: 0.37rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  margin-bottom: 0.32rem;
}
.contact-us.mobile .contact-us__ways div:last-child {
  margin-bottom: 0.64rem;
}
.contact-us.mobile .contact-us__form {
  display: flex;
  flex-direction: column;
  width: 9.15rem;
  padding: 0.43rem;
  background: #ffffff;
  border-radius: 0.21rem;
  box-shadow: 0.08rem 0.4rem 0.88rem 0rem rgba(0, 0, 0, 0.06);
}
.contact-us.mobile .contact-us__form form input,
.contact-us.mobile .contact-us__form form textarea {
  width: 100%;
  outline: none;
  padding: 0.21rem 0.42rem;
  border-radius: 0.11rem;
  border: 0.03rem solid rgba(0, 0, 0, 0.06);
  font-size: 0.4rem;
  color: rgba(0, 0, 0, 0.45);
}
.contact-us.mobile .contact-us__form form input {
  height: 1.07rem;
  line-height: 0.64rem;
  margin-bottom: 0.43rem;
}
.contact-us.mobile .contact-us__form form textarea {
  height: 4.27rem;
  line-height: 0.61rem;
}
.contact-us.mobile .contact-us__form form button {
  display: block;
  width: 2.35rem;
  height: 0.96rem;
  background: #377dff;
  border-radius: 0.11rem;
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
  outline: none;
  border: none;
  margin: 0.44rem auto 0.43rem;
} */
.contact-us__map.mb-container {
  padding-top: 0.64rem;
  height: 12.69rem;
}
