.asymptote-home__container .bottom-info__container {
    background: #20232E;
    padding: 48px 271px 72px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 14px;
    line-height: 1.571;
    letter-spacing: 0.2px;
}

.asymptote-home__container .bottom-info__container>div:not(:last-child) {
    margin-right: 112px;
}

/* .asymptote-home__container .bottom-info__container>div:first-child {
    margin-right: 80px;
} */

.asymptote-home__container .bottom-info__left-item-list>li:first-child {
    margin-top: 36px;
}

.asymptote-home__container .bottom-info__left-item-list>li {
    margin-top: 16px;
}

h3.bottom-info__list-item-title {
    color: rgba(255, 255, 255, 0.85);
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 30px;
    font-weight: 700;
}

.asymptote-home__container .bottom-info__center-list>ul>li,
.asymptote-home__container .bottom-info__right-list>ul>li {
    margin-top: 16px;
}

.asymptote-home__container .bottom-info__center-list>ul>li:first-child,
.asymptote-home__container .bottom-info__right-list>ul>li:first-child {
    margin-top: 30px;
}

.asymptote-home__container .bottom-info__container a {
    color: inherit;
    text-decoration: none;
    opacity: 0.9;
}

.asymptote-home__container .bottom-info__right-list-qrcode-label {
    color: #FFF;
    margin: 20px 0 12px 0;
}

.asymptote-home__container .bottom-info__right-list-qrcode-main {
    width: 98px;
    height: 98px;
}