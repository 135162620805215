.jobs-page {
  background: #f7faff;
}
.jobs-page .container {
  padding-top: 109px;
}
.jobs-page .jobs-page__info {
  text-align: center;
}
.jobs-page .jobs-page__info .jobs-page__info-title {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 40px;
}
.jobs-page .jobs-page__info .jobs-page__info-desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 26px;
  margin: 32px 0 47px;
}
/* 查询样式开始 */
.jobs-page .jobs-page__search input,
.jobs-page .jobs-page__search select,
.jobs-page .jobs-page__search button {
  border: none;
  outline: none;
}
.jobs-page .jobs-page__search input {
  width: 420px;
  height: 56px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0 16px;
}
.jobs-page .jobs-page__search select {
  width: 210px;
  height: 56px;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  padding: 0 40px 0 16px;
  margin: 0 24px;
}
/*清除ie的默认选择框样式清除，隐藏下拉箭头*/
select::-ms-expand {
  display: none;
}
.jobs-page .jobs-page__search .job-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #ffffff url("../../../../assets/images/jobs/select_icon.png")
    no-repeat 174px center;
  background-size: 24px 24px;
}
.jobs-page .jobs-page__search button {
  padding: 0 16px;
  background: #377dff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 15px;
  line-height: 36px;
}
.jobs-page .jobs-page__search button i {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background-color: transparent;
  margin-right: 4px;
}
/* 查询样式结束 */

/* 招聘岗位列表样式开始 */
.jobs-page .jobs-page__list {
  width: 100%;
  height: 544px;
}
.jobs-page .jobs-page__list .jobs-page__list-item {
  width: 100%;
  height: 64px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 16px;
  padding: 0 32px;
}
.jobs-page .jobs-page__list .jobs-page__item-active {
  background: rgba(0, 0, 0, 0.04);
}
.jobs-page .jobs-page__list-item .jobs-page__list-item-name {
  flex: 1;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
}
.jobs-page .jobs-page__list-item .jobs-page__list-item-detail {
  flex: 1;
  text-align: center;
}
.jobs-page .jobs-page__list-item-detail span {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
.jobs-page .jobs-page__list-item-detail span:nth-child(2) {
  position: relative;
  padding: 0 16px;
}
.jobs-page .jobs-page__list-item-detail span:nth-child(2)::after,
.jobs-page .jobs-page__list-item-detail span:nth-child(2)::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 0.5px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.45);
}
.jobs-page .jobs-page__list-item-detail span:nth-child(2)::after {
  left: 8px;
}
.jobs-page .jobs-page__list-item-detail span:nth-child(2)::before {
  right: 8px;
}
.jobs-page .jobs-page__list-item .jobs-page__list-item-action {
  flex: 1;
  text-align: right;
}
.jobs-page .jobs-page__list-item .jobs-page__list-item-action > div {
  display: inline-block;
  width: 104px;
  background: #377dff;
  border-radius: 2px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #ffffff;
  position: relative;
}
.jobs-page .jobs-page__list-item .jobs-page__list-item-action > div > i {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 4px;
  background-color: transparent;
  transition: all 0.2s;
  border-radius: 4px;
}
.jobs-page
  .jobs-page__list-item
  .jobs-page__list-item-action
  .job-page__list-item-action__select {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 5;
  margin: 0;
  padding: 0;
  padding-top: 4px;
}
.jobs-page .job-page__list-item-action__select li {
  list-style: none;
  width: 100%;
  background: #edf0f5;
  border-radius: 2px 2px 0px 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.2s;
}
.jobs-page .jobs-page_pager {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 0;
  bottom: 0;
  padding-top: 30px;
}
.jobs-page .jobs-page_pager button {
  width: 22px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: CENTER;
  color: #4e5969;
  line-height: 22px;
  margin-left: 18px;
  outline: none;
  border: none;
}
@media screen and (max-width: 1500px) {
  .jobs-page {
    background: #f7faff;
    padding-top: 94px;
  }
  .jobs-page .jobs-page__info .jobs-page__info-desc {
    margin: 12px 0 26px;
  }
  .jobs-page .jobs-page__list .jobs-page__list-item:nth-child(1) {
    margin-top: 24px;
  }
  .jobs-page .jobs-page__list .jobs-page__list-item {
    margin-top: 16px;
    padding: 0 24px;
  }
}
