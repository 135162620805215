.jobs-list__wrapper {
  background-color: #f9fafc;
  padding: 0.95rem 0 0.65rem;
  padding-bottom: 1.77rem;
}
.jobs-list__wrapper .jobs-list__title {
  font-size: 0.53rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
}
.jobs-list__wrapper .jobs-list__desc {
  font-size: 0.32rem;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
  margin: 0.43rem 0;
}
.jobs-list__wrapper .jobs-list__search .form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jobs-list__wrapper .form input,
.jobs-list__wrapper .form select,
.jobs-list__wrapper .form button {
  outline: none;
  border: none;
}

.jobs-list__wrapper .form input {
  width: 4.56rem;
  height: 1.28rem;
  background: #ffffff;
  border-radius: 0.11rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0.53rem;
  padding: 0.37rem 0.21rem;
}
.jobs-list__wrapper .form select {
  width: 2.45rem;
  height: 1.28rem;
  background: #ffffff;
  border-radius: 0.11rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0.53rem;
  padding: 0.37rem 0.96rem 0.37rem 0.21rem;
}
.jobs-list__wrapper .form select::-ms-expand {
  display: none;
}
.jobs-list__wrapper .form select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #ffffff url("../../../../assets/images/jobs/select_icon.png")
    no-repeat 1.81rem center;
  background-size: 0.43rem 0.43rem;
}

.jobs-list__wrapper .form button {
  width: 1.71rem;
  height: 0.85rem;
  background: #377dff;
  border-radius: 0.11rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jobs-list__wrapper .form button span {
  font-size: 0.32rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 0.53rem;
  white-space: nowrap;
}
.jobs-list__wrapper .jobs-box {
  margin-top: 0.43rem;
}
.jobs-list__wrapper .jobs-box .jobs-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.03rem solid rgba(0, 0, 0, 0.06);
  border-radius: 0.11rem;
  margin-bottom: 0.26rem;
  padding: 0.21rem;
  transition: all 0.2s;
}
.jobs-list__wrapper .jobs-box .jobs-item__active {
  background: #edf0f5;
}
.jobs-list__wrapper .jobs-box .jobs-item:last-child {
  margin-bottom: 0;
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item-name {
  font-size: 0.37rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.59rem;
  min-width: 3.5rem;
  width: 2.61rem;
  flex-shrink: 0;
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item-detail span {
  font-size: 0.32rem;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0.53rem;
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item-detail span:nth-child(2) {
  position: relative;
  padding: 0 0.21rem;
}
.jobs-list__wrapper
  .jobs-box
  .jobs-item
  .jobs-item-detail
  span:nth-child(2)::before {
  position: absolute;
  width: 0rem;
  height: 0.32rem;
  border: 0.01rem solid rgba(0, 0, 0, 0.45);
  content: "";
  left: 0.1rem;
  top: 50%;
  transform: translateY(-50%);
}
.jobs-list__wrapper
  .jobs-box
  .jobs-item
  .jobs-item-detail
  span:nth-child(2)::after {
  position: absolute;
  width: 0rem;
  height: 0.32rem;
  border: 0.01rem solid rgba(0, 0, 0, 0.45);
  content: "";
  right: 0.1rem;
  top: 50%;
  transform: translateY(-50%);
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item_action {
  width: 1.81rem;
  height: 0.85rem;
  background: #377dff;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item_action span {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
}
.jobs-list__wrapper .jobs-box .jobs-item .jobs-item_action i {
  display: inline-block;
  width: 0.43rem;
  height: 0.43rem;
  background-color: transparent;
  margin-left: 0.11rem;
  transition: all 0.2s;
}
.jobs-list__wrapper .jobs-item_action ul {
  position: absolute;
  left: 0;
  top: 0.96rem;
  width: 1.82rem;

  z-index: 1;
  background: #edf0f5;
  border-radius: 0.05rem 0.05rem 0rem 0rem;
}

.jobs-list__wrapper .jobs-item_action ul,
.jobs-list__wrapper .jobs-item_action li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.jobs-list__wrapper .jobs-item_action li {
  height: 0;
  overflow: hidden;
  transition: height 0.2s;
  text-align: center;
  line-height: 0.59rem;
  font-size: 0.37rem;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list__wrapper .jobs-more {
  display: block;
  width: 2.35rem;
  height: 0.96rem;
  background: #377dff;
  border-radius: 0.11rem;
  margin: 0.43rem auto 0;
  font-size: 0.37rem;
  line-height: 0.96rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
}
