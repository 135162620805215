.footer-root {
  background-color: #4c536e;
}
.footer-root .top {
  padding: 0.43rem 0 0.85rem;
}
.footer-root .top .footer-logo {
  width: 100%;
}
.footer-root .top .footer-logo img {
  width: 2.77rem;
  height: 0.59rem;
}
.footer-root .top .top-base {
  padding: 0 0.85rem;
}
.footer-root .top .footer-base__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 0.85rem;
}
.footer-root .top .footer-base__wrapper .footer-base__wrapper-item {
  width: 50%;
  flex-shrink: 0;
}
.footer-root
  .top
  .footer-base__wrapper
  .footer-base__wrapper-item:nth-child(2) {
  padding-left: 0.85rem;
}
.footer-root dl,
.footer-root dt,
.footer-root dd {
  margin: 0;
  padding: 0;
}
.footer-root .top .footer-base__wrapper .footer-base__wrapper-item dt {
  font-size: 0.37rem;
  color: rgba(255, 255, 255, 0.85);
  line-height: 0.59rem;
  margin-bottom: 0.32rem;
}
.footer-root .top .footer-base__wrapper .footer-base__wrapper-item dd {
  margin-bottom: 0.21rem;
  line-height: 0;
}
.footer-root .top .footer-base__wrapper .footer-base__wrapper-item dd a {
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 0.65);
  line-height: 0.53rem;
}
.footer-root .top .top-contact {
  display: flex;
  padding: 0 0.53rem;
  margin-top: 0.85rem;
}
.footer-root .top .top-contact > div {
  width: 50%;
  flex-shrink: 0;
}
.footer-root .top .top-contact .top-contact__content dt {
  font-size: 0.37rem;
  color: rgba(255, 255, 255, 0.85);
  line-height: 0.59rem;
}
.footer-root .top .top-contact .top-contact__content dt {
  font-size: 0.37rem;
  color: rgba(255, 255, 255, 0.85);
  line-height: 0.59rem;
  margin-left: 0.64rem;
  margin-bottom: 0.32rem;
}
.footer-root .top .top-contact .top-contact__content img {
  width: 0.43rem;
  height: 0.43rem;
  margin-right: 0.21rem;
}
.footer-root .top .top-contact .top-contact__content dd {
  display: flex;
  margin-bottom: 0.21rem;
}
.footer-root .top .top-contact .top-contact__content dd p {
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 0.65);
  line-height: 0.53rem;
  width: 3.84rem;
}
.footer-root .top .top-contact .top-contact__content dd:last-child p {
  width: 3.39rem;
}
.footer-root .top .top-contact__qrcode .top-contact__qrcode-title,
.footer-root .top .top-contact__qrcode .top-contact__qrcode-desc {
  width: 100%;
  font-size: 0.37rem;
  color: #ffffff;
  line-height: 0.59rem;
}
.footer-root .top .top-contact__qrcode .top-contact__qrcode-title {
  padding-left: 0.37rem;
}
.footer-root .top .top-contact__qrcode .top-contact__qrcode-desc {
  padding-left: 1.28rem;
}
.footer-root .top .top-contact__qrcode-detail .top-contact__qrcode-detail-pic {
  display: block;
  width: 2.61rem;
  height: 2.61rem;
  background: #c4c4c4;
  border-radius: 0.21rem;
  margin: 0.32rem 0 0.43rem 1.09rem;
}
.footer-root .top .top-contact__qrcode-detail .top-contact__qrcode-pic__list {
  width: 2.61rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer-root
  .top
  .top-contact__qrcode-detail
  .top-contact__qrcode-pic__list
  img {
  width: 0.64rem;
  height: 0.64rem;
}

/* 底部版权样式开始 */
.footer-root .bottom {
  height: 1.49rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.21rem 0;
}
.footer-root .bottom p:nth-child(1) {
  font-size: 0.29rem;
  color: rgba(255, 255, 255, 0.65);
  line-height: 0.48rem;
}
.footer-root .bottom p:nth-child(2) {
  font-size: 0.29rem;
  color: rgba(255, 255, 255, 0.65);
  line-height: 0.48rem;
}
.footer-root .bottom p:nth-child(2) span:nth-child(2) {
  padding: 0 0.43rem;
}
/* 底部版权样式结束 */
