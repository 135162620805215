.asymptote-home__container .project-results__container {
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/projectResults/mask.png") center/cover no-repeat;
    padding: 112px;
    padding-bottom: 104px;
    text-align: center;
}

.asymptote-home__container .project-results__container>h3 {
    color: #1A202C;
    font-size: 32px;
    line-height: 1.25;
    /* margin-bottom: 99px; */
    font-weight: 700;
}

.asymptote-home__container .project-results__container>h3>strong {
    color: #2b6cb9;
}

/* 轮播卡片 */
.asymptote-home__container .project-results__scroll-container {
    overflow: hidden;
    width: 1542px;
    /* height: 394px; */
    /* padding-top: 46px; */
    height: 600px;
    padding-top: 150px;
    margin: 0 auto;
    /* margin-bottom: 44px; */
    text-align: left;
}

.asymptote-home__container .project-results__card-list {
    gap: 97px;
}

.asymptote-home__container .project-results__card-list>div {
    flex: 0 0 430px;
    height: 300px;
}

.asymptote-home__container .project-results__card {
    cursor: pointer;
    overflow: hidden;
    padding: 16px;
    height: 300px;
    border-radius: 8px;
    transition: transform 0.5s linear;
    color: #fff;
    position: relative;
    filter: drop-shadow(0px 0px 5px rgba(64, 128, 255, 0.2));
}

.asymptote-home__container .project-results__card:first-child {
    margin-left: 46px;
}

.asymptote-home__container .project-results__card.casc {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 2.17%, rgba(19, 48, 74, 0.75) 52.33%, #13304A 100%), url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/projectResults/background/CASC.png") no-repeat center/100%;
}

.asymptote-home__container .project-results__card.casic {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 2.17%, rgba(24, 44, 64, 0.75) 52.33%, #182C40 100%), url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/projectResults/background/CASIC.png") no-repeat center/cover;
}

.asymptote-home__container .project-results__card.eikto {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 2.17%, rgba(28, 65, 77, 0.75) 52.33%, #1C414D 100%), url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/projectResults/background/EIKTO.png") no-repeat center/cover;
}

.asymptote-home__container .project-results__card.llt {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 2.17%, rgba(24, 44, 64, 0.75) 52.33%, #182C40 100%), url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/projectResults/background/LLT.png") no-repeat center/cover;
}

.asymptote-home__container .project-results__card.byd {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(52, 24, 24, 0) 2.17%, rgba(52, 24, 24, 0.75) 52.33%, #341818 100%), url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/projectResults/background/BYD.png") no-repeat center/cover;
}

.asymptote-home__container .project-results__card.active {
    transform: scale(1.3);
    filter: drop-shadow(0px 0px 30px rgba(64, 128, 255, 0.2));
}

.asymptote-home__container .project-results__card-content {
    flex-basis: 100%;
}

.asymptote-home__container .project-results__card-content>div:not(:last-child) {
    margin-bottom: 12px;
}

/* 卡片标题 */
.asymptote-home__container .project-results__card-title {
    font-size: 24px;
    line-height: 1.333;
    flex-shrink: 0;
    font-weight: 700;
}

.asymptote-home__container .project-results__card.active .project-results__card-title {
    font-size: 18px;
    line-height: 1;
    flex-shrink: 0;
}

.asymptote-home__container .title-separator {
    width: 2px;
    height: 15px;
    background-color: #fff;
    border-radius: 1px;
}

.asymptote-home__container .project-results__card-sub-title {
    font-size: 16px;
    line-height: 1.5;
}

.asymptote-home__container .project-results__card.active .project-results__card-sub-title {
    font-size: 14x;
}

/* 描述 */
.asymptote-home__container .project-results__card .description {
    color: rgba(255, 255, 255, 0.8);
    font-size: 10.7px;
    line-height: 1.5;
    height: 0;
    overflow: hidden;
    font-weight: 300;
}

.asymptote-home__container .project-results__card.active .description {
    height: auto;
}

/* 量化数据 */
/* .quantification-data-container:not(:last-child) {
    margin-right: 70px;
} */
.quantification-data-container:nth-child(2) {
    margin-left: auto;
    margin-right: auto;
}

.asymptote-home__container .quantification-data-content {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 4px;
    font-weight: 700;
}

.asymptote-home__container .project-results__card.active .quantification-data-content {
    font-size: 16px;
}

.asymptote-home__container .quantification-data-label {
    color: #A1AEBF;
    font-size: 12px;
    line-height: 1.667;
}

.asymptote-home__container .project-results__card.active .quantification-data-label {
    font-size: 9.2px;
}

.asymptote-home__container .quantification-data-label::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #4299e1;
    margin-right: 6px;
}

/* 轮播下方小图片 */
.asymptote-home__container .project-results__icon-container {
    position: relative;
    border: none;
    ;
    background: transparent;
}

.asymptote-home__container .project-results__icon-container>button {
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

.asymptote-home__container .project-results__icon {
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: -1; */
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s linear;
}

.asymptote-home__container .project-results__icon.active {
    opacity: 1;
}