.product-services__supports {
  background: #f9fafc;
}
.product-services__supports .mb-container {
  padding: 0 0.43rem 0.42rem;
}
.product-services__supports .product-services__supports-title {
  padding-top: 0.85rem;
  font-size: 0.53rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
}
.product-services__supports .product-services__supports-pic {
  width: 100%;
  height: 5.49rem;
  margin: 0.85rem 0;
}
.product-services__supports
  .product-services__supports-list
  .product-services__supports-item
  .title {
  font-size: 0.42rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.69rem;
}
.product-services__supports
  .product-services__supports-list
  .product-services__supports-item
  .desc {
  font-size: 0.37rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  margin: 0.21rem 0 0.43rem;
}
