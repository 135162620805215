.home-company-feature {
  background-color: #f9fafc;
  padding-top: 0.85rem;
}
.home-company-feature .mb-container {
  padding: 0.43rem;
  margin-bottom: 0.43rem;
}
/* card1样式开始 */
.home-company-feature .card1 {
  text-align: center;
  height: 10.48rem;
  background: #74c3c7;
  border-radius: 0.21rem;
  box-shadow: 0.05rem 0.11rem 0.4rem 0rem rgba(0, 0, 0, 0.06);
}
.home-company-feature .card1 .home-company-feature__title {
  font-size: 0.53rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.75rem;
}
.home-company-feature .card1 .home-company-feature__title:after {
  display: block;
  content: '';
  width: 0.64rem;
  height: 0.08rem;
  background-color: #ffffff;
  border-radius: 0.21rem;
  margin: 0.32rem auto;
}
.home-company-feature .card1 .home-company-feature__title-desc {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
}
.home-company-feature .card1 .home-company-feature__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.32rem;
}
.home-company-feature .card1 .home-company-feature__list-item {
  width: 4.03rem;
  height: 2.67rem;
  flex-shrink: 0;
  background: #ffffff;
  border-radius: 0.11rem;
  padding: 0.32rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-company-feature .card1 .home-company-feature__list-item:nth-child(1),
.home-company-feature .card1 .home-company-feature__list-item:nth-child(2) {
  margin-bottom: 0.24rem;
}
.home-company-feature .card1 .home-company-feature__list-item i {
  display: block;
  width: 0.8rem;
  height: 0.85rem;
  background-color: transparent;
}
.home-company-feature .card1 .home-company-feature__list-item p {
  font-size: 0.32rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
  margin-top: 0.11rem;
}
/* card1样式结束 */

/* card2样式开始 */
.home-company-feature .card2 {
  height: 9.55rem;
  background: #9e84e3;
  text-align: center;
  border-radius: 0.21rem;
  box-shadow: 0.05rem 0.11rem 0.4rem 0rem rgba(0, 0, 0, 0.06);
}
.home-company-feature .card2 .home-company-feature__title {
  font-size: 0.53rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.75rem;
}
.home-company-feature .card2 .home-company-feature__title-desc {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
  margin-top: 0.32rem;
}
.home-company-feature .card2 .home-company-feature__title-desc:after {
  display: block;
  content: '';
  width: 0.64rem;
  height: 0.08rem;
  background-color: #ffffff;
  border-radius: 0.21rem;
  margin: 0.43rem auto;
}
.home-company-feature .card2 .home-company-feature__title-desc:after {
  display: block;
  content: '';
  width: 0.64rem;
  height: 0.08rem;
  background-color: #ffffff;
  border-radius: 0.21rem;
  margin: 0.43rem auto;
}
.home-company-feature .card2 .home-company-feature__status {
  font-size: 0.37rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.59rem;
}
.home-company-feature .card2 .home-company-feature__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.43rem;
}
.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item {
  flex-shrink: 0;
  width: 4.03rem;
  height: 1.17rem;
  background: #ffffff;
  border-radius: 0.11rem;
  padding: 0.32rem;
  margin-bottom: 0.24rem;
  -webkit-line-clamp: 1;
}
.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item .paragraph_limit {
  -webkit-line-clamp: 1;
}
.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item:nth-child(5) {
  margin-bottom: 0;
}
.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item a > span {
  font-size: 0.32rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
}
.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item:last-child a > span {
  color: #377dff;
}

.home-company-feature .card2 .home-company-feature__list .home-company-feature__list-item a > img {
  width: 0.53rem;
  height: 0.53rem;
}
/* card2样式结束 */

/* card3样式开始 */
.home-company-feature .card3 {
  text-align: center;
  height: 8.11rem;
  background: #4c536e;
  border-radius: 0.21rem;
  box-shadow: 0.05rem 0.11rem 0.4rem 0rem rgba(0, 0, 0, 0.06);
}
.home-company-feature .card3 .home-company-feature__title {
  font-size: 0.53rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.75rem;
}
.home-company-feature .card3 .home-company-feature__title:after {
  display: block;
  content: '';
  width: 0.64rem;
  height: 0.08rem;
  background-color: #ffffff;
  border-radius: 0.21rem;
  margin: 0.32rem auto;
}
.home-company-feature .card3 .home-company-feature__desc {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
}
.home-company-feature .card3 .home-company-features__list {
  margin-top: 0.53rem;
}
.home-company-feature .card3 .home-company-features__list .home-company-features__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.43rem;
}
.home-company-feature .card3 .home-company-features__list .home-company-features__list-item:last-child {
  margin-bottom: 0;
}
.home-company-feature .card3 .home-company-features__list-item__icon {
  display: block;
  flex-shrink: 0;
  width: 0.96rem;
  height: 0.96rem;
  background: #393e53;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-company-feature .card3 .home-company-features__list-item__icon img {
  width: 0.48rem;
  height: 0.48rem;
}
.home-company-feature .card3 .home-company-features__list-item-detail {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 0.32rem;
}
.home-company-feature .card3 .home-company-features__list-item-detail p {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
}
.home-company-feature .card3 .home-company-features__list-item-detail span {
  font-size: 0.37rem;
  color: #ffffff;
  line-height: 0.59rem;
}
/* card3样式结束 */
