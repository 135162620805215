.asymptote-home__container .product-introduction__container {
    height: 220vh;
    position: relative;
    background-color: #f8fbff;
}

.asymptote-home__container .product-introduction__content-container {
    position: sticky;
    height: 100vh;
    margin: 0 auto;
    top: 0;
}
.product-introduction__content-container-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/productIntroduction/mask.png") center/cover no-repeat;
    z-index: -1;
    opacity: 0.8;
}

.asymptote-home__container .product-introduction__content-info-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 427px;
    font-size: 32px;
    line-height: 1.25;
    color: #1a202c;
    z-index: 1;
}

.asymptote-home__container .product-introduction__trait {
    position: relative;
    color: #2b6cb0;
    left: -32px;
    font-weight: 700;
}

.asymptote-home__container .product-introduction__name {
    margin: 4px 0 52px;
    font-weight: 700;
}

.asymptote-home__container .product-introduction__abbr {
    color: #2b6cb0;
    font-weight: bold;
    letter-spacing: -1.5px;
}
.asymptote-home__container .product-introduction__detail-item {
    margin-top: 24px;
}
.asymptote-home__container .product-introduction__detail-item>h3 {
    font-size: 20px;
    line-height: 1.4;
    color: rgba(0,0,0,0.7);
    font-weight: 700;
}
.asymptote-home__container .product-introduction__detail-item>p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.571;
    color: #728095;
    font-weight: 300;
}
.asymptote-home__container .product-introduction__detail-item>p::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #4299e1;
    margin-right: 12px;
}

.asymptote-home__container .product-introduction__content-video-container {
    width: 1092px;
    height: 623px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 75px;
}
.asymptote-home__container .product-introduction__content-video-container>video {
    width: 100%;
}

.production-introduction__dot-button-list {
    position: absolute;
    gap: 8px;
    bottom: -93px;
    left: 504px;
}
.product-introduction__dot-button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    background-color: #e5e6eb;
    cursor: pointer;
    border: none;
    transition: background-color .2s linear;
}
.product-introduction__dot-button.active {
    background-color: #2b6cb0;
}