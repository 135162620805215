.about-us__photo-wall .about-us__photo-wall-top {
  width: 100%;
  height: 335px;
}
.about-us__photo-wall .about-us__photo-wall-top span {
  font-size: 32px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 40px;
}
/* 顶部样式结束 */
.about-us__photo-wall .about-us__photo-wall-bottom {
  background: #f7faff;
  flex: 1;
}
.about-us__photo-wall .about-us__photo-wall-bottom .container {
  position: relative;
  height: 100%;
}
.about-us__photo-wall .about-us__photo-wall-bottom > .container div {
  position: absolute;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.about-us__photo-wall
  .about-us__photo-wall-bottom
  .container
  .about-us__photo-wall-bottom__pic01 {
  width: 948px;
  height: 414px;
  left: 50%;
  transform: translateX(-50%);
  top: -107px;
  z-index: 2;
}
.about-us__photo-wall
  .about-us__photo-wall-bottom
  .container
  .about-us__photo-wall-bottom__pic02 {
  width: 423px;
  height: 274px;
  left: 123px;
  top: 219px;
  z-index: 1;
}
.about-us__photo-wall
  .about-us__photo-wall-bottom
  .container
  .about-us__photo-wall-bottom__pic03 {
  width: 328px;
  height: 246px;
  right: 0;
  top: -51px;
  z-index: 3;
}
.about-us__photo-wall
  .about-us__photo-wall-bottom
  .about-us__photo-wall-bottom__pic04 {
  width: 222px;
  height: 222px;
  right: 452px;
  bottom: 64px;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  .about-us__photo-wall
    .about-us__photo-wall-bottom
    .container
    .about-us__photo-wall-bottom__pic01 {
    width: 792px;
    height: 346px;
    top: -94px;
  }
  .about-us__photo-wall
    .about-us__photo-wall-bottom
    .container
    .about-us__photo-wall-bottom__pic02 {
    width: 345px;
    height: 224px;
    top: 198px;
    left: 102px;
  }
  .about-us__photo-wall
    .about-us__photo-wall-bottom
    .container
    .about-us__photo-wall-bottom__pic03 {
    width: 282px;
    height: 212px;
    top: -46px;
  }
  .about-us__photo-wall
    .about-us__photo-wall-bottom
    .about-us__photo-wall-bottom__pic04 {
    width: 178px;
    height: 178px;
    bottom: 74px;
    right: 384px;
  }
}
