.jobs-page__join {
  padding: 98px 0;
  background: #f7faff;
}

.jobs-page__join .jobs-page__join-content {
  width: 1194px;
  height: 426px;
  margin: 0 auto;
  background-color: rgb(76, 83, 110);
  border-radius: 16px;
  text-align: center;
  padding-top: 32px;
  color: #ffffff;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-title {
  font-size: 32px;
  line-height: 40px;
  position: relative;
  padding-bottom: 32px;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-title::after {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  content: "";
  display: block;
  width: 24px;
  height: 3px;
  background-color: #ffffff;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-desc {
  font-size: 18px;
  line-height: 28px;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-contact i {
  display: inline-block;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  margin-left: 16px;
  vertical-align: middle;
  background-color: transparent;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-contact i:hover {
  opacity: 1;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-contact {
  font-size: 18px;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 32px;
}

.jobs-page__join .jobs-page__join-content .jobs-page__join-follow {
  font-size: 20px;
  line-height: 32px;
}

.jobs-page__join .jobs-page__join-list {
  margin-top: 32px;
}

.jobs-page__join .jobs-page__join-list .jobs-page__join-item {
  width: 306px;
  height: 122px;
  background: #ffffff;
  border-radius: 8px;
  margin-right: 16px;
  padding: 23px 16px 23px 20px;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
}

.jobs-page__join .jobs-page__join-list .jobs-page__join-item:last-child {
  margin-right: 0;
}

.jobs-page__join .jobs-page__join-list .jobs-page__join-item img {
  display: block;
  width: 76px;
  height: 76px;
  border-radius: 8px;
}

.jobs-page__join .jobs-page__join-list .jobs-page__join-item > div {
  margin-left: 16px;
}

.jobs-page__join
  .jobs-page__join-list
  .jobs-page__join-item
  .jobs-page__join-item-name {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}

.jobs-page__join
  .jobs-page__join-list
  .jobs-page__join-item
  .jobs-page__join-item-desc {
  font-size: 12px;
  line-height: 20px;
  -webkit-line-clamp: 2;
}
@media screen and (max-width: 1500px) {
  .jobs-page__join {
    padding: 137px 0 69px;
  }

  .jobs-page__join .jobs-page__join-content {
    width: 996px;
    height: 356px;
    padding: 24px;
  }

  .jobs-page__join .jobs-page__join-content .jobs-page__join-title {
    padding-bottom: 24px;
  }

  .jobs-page__join .jobs-page__join-content .jobs-page__join-title::after {
    bottom: 12px;
  }
  .jobs-page__join .jobs-page__join-content .jobs-page__join-contact {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .jobs-page__join .jobs-page__join-list {
    margin-top: 16px;
  }

  .jobs-page__join .jobs-page__join-list .jobs-page__join-item {
    width: 282px;
    height: 104px;
    padding: 14px 12px;
  }

  .jobs-page__join .jobs-page__join-list .jobs-page__join-item > div {
    margin-left: 4px;
  }
}
