.product-services__wrapper {
  padding: 2.67rem 0 0.85rem;
  background: #f0f4fc;
}
.product-services__wrapper .product-services__wrapper-title {
  font-size: 0.64rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
  margin: 0;
}
.product-services__wrapper .product-services__wrapper-desc {
  font-size: 0.32rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
  margin: 0.43rem 0 0.85rem;
}
.product-services__wrapper .product-services__wrapper-main {
  width: 9.15rem;
  height: 6.56rem;
  background-color: transparent;
  position: relative;
}
.product-services__wrapper .product-services__wrapper-center {
  width: 3.31rem;
  height: 3.31rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0.85rem;
  background-color: transparent;
  transition: all 0.5s;
}
.product-services__wrapper .product-services__wrapper-slide {
  width: 1.07rem;
  height: 1.07rem;
  position: absolute;
}
.product-services__wrapper
  .product-services__wrapper-main
  .product-services__wrapper-slide:nth-child(2) {
  left: 1.6rem;
  top: 0.59rem;
}
.product-services__wrapper
  .product-services__wrapper-main
  .product-services__wrapper-slide:nth-child(3) {
  right: 0.72rem;
  top: 2.16rem;
}
.product-services__wrapper
  .product-services__wrapper-main
  .product-services__wrapper-slide:nth-child(4) {
  left: 0.53rem;
  top: 3.23rem;
}
.product-services__wrapper
  .product-services__wrapper-main
  .product-services__wrapper-slide:nth-child(5) {
  right: 1.79rem;
  bottom: 0.32rem;
}

.product-services__wrapper .product-services__wrapper-slide > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #a4c3fc;
  position: relative;
}
.product-services__wrapper .product-services__wrapper-slide img {
  position: absolute;
  width: 1.07rem;
  height: 1.07rem;
  left: 0;
  top: -0.54rem;
}
.product-services__wrapper .product-services__wrapper-slide span {
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.53rem;
}
.product-services__wrapper .product-services__wrapper-main__detail {
  text-align: center;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  line-height: 1;
}
.product-services__wrapper
  .product-services__wrapper-main__detail
  .product-services__wrapper-main__detail-title {
  font-size: 0.37rem;
  color: #4c536e;
  line-height: 0.59rem;
}
.product-services__wrapper
  .product-services__wrapper-main__detail
  .product-services__wrapper-main__detail-info
  span {
  font-size: 0.32rem;
  color: #377dff;
  line-height: 0.53rem;
}
.product-services__wrapper
  .product-services__wrapper-main__detail
  .product-services__wrapper-main__detail-info
  span:nth-child(2) {
  padding: 0 0.43rem;
}
.product-services__wrapper .product-services__wrapper-more-btn {
  display: block;
  width: 2.35rem;
  background: #377dff;
  border-radius: 0.11rem;
  margin: 0.85rem auto 0;
  font-size: 0.37rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
  line-height: 0.96rem;
}
