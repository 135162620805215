html,
body,
p,
ul,
li button {
  padding: 0;
  margin: 0;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
}
a,
a:hover {
  color: #202020;
  text-decoration: none;
}
button,
input,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
img {
  width: 100%;
  height: 100%;
}
.global__user-select-none {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.global__oneText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.global__bg {
  background-color: rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.paragraph_limit {
  overflow: hidden;
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
  word-break: break-all;
}
.full-box {
  width: 100%;
  height: 100%;
}
.container {
  padding-left: 0;
  padding-right: 0;
}
.mb-container {
  width: 9.15rem;
  margin: 0 auto;
}
.min {
  min-width: 1200px;
}
@media screen and (max-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
  .full-page {
    width: 100%;
    height: 900px;
  }
}
@media screen and (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1440px;
  }
  .full-page {
    width: 100%;
    height: 960px;
  }
}

.flex-container {
	display: flex;
}

.inline-flex-container {
	display: inline-flex;
}

.justify-center {
	justify-content: center;
}

.justify-space-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.align-start {
	align-items: start;
}

.align-end {
	align-items: end;
}

.asymptote-home__container p,
h2,
h3,
h4,
h5,
ul,
ol {
	padding: 0;
	margin: 0;
}

.asymptote-home__container ul,
ol {
	list-style: none;
}

.asymptote-home__container li {
	list-style-type: none;
}

.asymptote-home__container * {
	box-sizing: border-box;
}

.asymptote-home__container {
  /* width: 1920px; */
  min-width: 1900px;
  height: 100%;
}
.asymptote-home__container img {
  width: auto;
  height: auto;
}

.asymptote-home__container>div {
  overflow: hidden;
}