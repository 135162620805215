.about-us__company-team {
  background-color: #f9fafc;
  padding: 132px 0 223px;
}
.about-us__company-team .about-us__company-team__title-desc {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
  text-align: center;
  margin-bottom: 64px;
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item {
  width: 379px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  box-shadow: 3px 15px 33px 0px rgba(241, 241, 242, 0.65);
  margin-right: 16px;
  padding: 32px;
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item
  > div {
  flex-shrink: 0;
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item:last-child {
  margin-right: 0;
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item__photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 32px;
  border: 0.5px solid rgba(0, 0, 0, 0.06);
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item__name {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 26px;
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item__title {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 23px;
  margin-top: 12px;
  margin-bottom: 16px;
}
.about-us__company-team
  .about-us__company-team__list
  .about-us__company-team__list-item__desc {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 23px;
}
@media screen and (max-width: 1500px) {
  .about-us__company-team .about-us__company-team__title-desc {
    font-size: 20px;
  }
  .about-us__company-team
    .about-us__company-team__list
    .about-us__company-team__list-item {
    width: 324px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    box-shadow: 3px 15px 33px 0px rgba(241, 241, 242, 0.65);
  }
  .about-us__company-team
    .about-us__company-team__list
    .about-us__company-team__list-item__photo {
    width: 100px;
    height: 100px;
  }
  .about-us__company-team
    .about-us__company-team__list
    .about-us__company-team__list-item__title {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}
