.home-banner {
  width: 10rem;
  height: 12.24rem;
}

.home-banner .home-banner__swiper,
.home-banner .homer-banner__swiper-slide {
  width: 100%;
  height: 100%;
}
.home-banner .homer-banner__swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.83rem;
  transition: all 0.8s ease;
}
.home-banner .homer-banner__swiper-slide .home-banner__swiper-slide__ch-title {
  width: 8.21rem;
  text-align: center;
  font-size: 0.64rem;
  font-weight: 700;
  line-height: 0.75rem;
}
.home-banner .homer-banner__swiper-slide .home-banner__swiper-slide__en-title {
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.53rem;
  margin: 0.21rem 0 0.85rem;
}
.home-banner .homer-banner__swiper-slide .home-banner__swiper-slide__more {
  width: 2.35rem;
  height: 0.96rem;
  background: #377dff;
  border-radius: 0.11rem;
  font-size: 0.37rem;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  line-height: 0.96rem;
}
.home-banner .home-banner__swiper {
  position: relative;
}
.home-banner .homer-banner__swiper-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.32rem;
  display: flex;
}
.home-banner .homer-banner__swiper-indicator span {
  display: block;
  width: 0.85rem;
  height: 0.08rem;
  background: #ffffff;
  border-radius: 2.67rem;
  margin-right: 0.21rem;
  transition: all 0.8s ease;
}
.home-banner .homer-banner__swiper-indicator span:last-child {
  margin-right: 0;
}
.home-banner .homer-banner__swiper-indicator .banner__swiper-indicator__active {
  background: #377dff;
}