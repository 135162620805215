/* awards */
.asymptote-home__container .awards__container {
    background: #F8FBFF;
    padding: 112px 380px;
}

.asymptote-home__container .awards__container>h2 {
    color: #1A202C;
    font-size: 32px;
    line-height: 1.25;
    margin: 0 auto;
    width: 1128px;
    font-weight: 700;
}
.asymptote-home__container .awards__container>p {
    color: #728095;
    font-size: 14px;
    line-height: 1.571;
    width: 1128px;
    margin: 24px auto 58px;
}

.asymptote-home__container .awards__main {
    color: #728095;
    text-align: center;
    font-size: 14px;
    padding-left: 56px;
    width: 1100px;
    margin: 0 auto;
}
.asymptote-home__container .awards__main-row {
    gap: 92px;
}
.asymptote-home__container .awards__main-row:first-child {
    margin-bottom: 40px;
}

.asymptote-home__container .awards__main-row-item-icon {
    opacity: 0.85;
    margin: 0 auto 20px;
}
