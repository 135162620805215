.section-one__container {
    height: 960px;
    position: relative;
    overflow: hidden;
}

.section-one__mask {
    mix-blend-mode: multiply;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(7, 20, 33, 0.75) 0%, rgba(7, 20, 33, 0.55) 24.8%, rgba(7, 20, 33, 0.30) 49.6%, rgba(7, 20, 33, 0.15) 74.39%, rgba(7, 20, 33, 0.00) 99.19%);
}

.section-one__video-container {
    position: absolute;
    top: -101px;
    width: 100%;
}

.section-one__video-container>video {
    width: 100%;
}

.section-one__text-container {
    position: relative;
    z-index: 1;
    left: 271px;
}

.section-one__text-container>h3 {
    color: rgba(255, 255, 255, 0.95);
    font-size: 64px;
    line-height: 1.125;
    margin: 12px 0 24px;
    font-weight: 700;
}

.section-one__text-container>h4 {
    color: #FFF;
    font-size: 32px;
    line-height: 1.25;
}

.asymptote-home__container  .section-one__text-container>p {
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 1px;
    margin-bottom: 64px;
}

.asymptote-home__container .section-one__text-container .section-one__gradient-button {
    display: inline-block;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    background: linear-gradient(96deg, #276DB7 33.38%, #1973E9 97.07%);
    transition: opacity 0.2s linear;
    cursor: pointer;
}
.section-one__gradient-button:hover {
    opacity: 0.8;
}