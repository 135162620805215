.asymptote-home__container .partner-show__container {
    position: relative;
    padding: 112px 0;
    overflow: hidden;
    background-color: #f8fbff;
}

.asymptote-home__container .partner-show__description {
    position: absolute;
    top: 33%;
    left: 250px;
    width: 341px;
    z-index: 2;
}

.asymptote-home__container .partner-show__description>h3 {
    color: #1A202C;
    font-size: 32px;
    line-height: 1.25;
    font-weight: 700;
}
.asymptote-home__container .partner-show__description>h3>strong {
    color: #2B6CB0;
}

.asymptote-home__container .partner-show__description>p {
    margin-top: 24px;
    color: #4B5567;
    font-size: 14px;
    line-height: 1.571;
    letter-spacing: 0.3px;
}

.asymptote-home__container .partner-show__scroll-container {
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/crossSlideSection/mask.png") center/cover no-repeat;
    overflow: hidden;
    width: 1696px;
    position: relative;
    margin: 0 auto;
}
.asymptote-home__container .partner-show__scroll-row {
    position: relative;
    margin-bottom: 24px;
    z-index: 0;
}
.asymptote-home__container .partner-show__scroll-row-item {
    flex: 0 0 253px;
}
.asymptote-home__container .partner-show__scroll-row-item:not(:last-child) {
    margin-right: 24px;
}

@keyframes slideToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(1385px);
    }
}
@keyframes slideToLeft {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-1385px);
    }
}
.asymptote-home__container .partner-show__scroll-row:nth-child(odd) {
    animation: slideToRight 50s linear infinite;
}
.asymptote-home__container .partner-show__scroll-row:nth-child(even) {
    animation: slideToLeft 50s linear infinite;
}

.asymptote-home__container .partner-show__scroll-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: radial-gradient(circle at 960px, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, #f8fbff 38%);
}

.partner-show__top-mask {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/crossSlideSection/topMask.png") center/cover no-repeat;
}