.about-us__team-leader {
  background: #f9fafc;
  text-align: center;
  padding: 0.85rem 0;
}
.about-us__team-leader .about-us__team-leader-title {
  font-size: 0.53rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
}
.about-us__team-leader .about-us__team-leader-desc {
  font-size: 0.37rem;
  font-weight: Normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  margin: 0.21rem 0 0.85rem;
}
.about-us__team-leader .about-us__team-leader-list,
.about-us__team-leader .about-us__team-leader-list .about-us__team-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-us__team-leader .about-us__team-leader-list .about-us__team-item {
  width: 8.29rem;
  height: 11.44rem;
  background: #ffffff;
  border: 0.01rem solid rgba(0, 0, 0, 0.06);
  border-radius: 0.21rem;
  box-shadow: 0.08rem 0.4rem 0.88rem 0rem rgba(241, 241, 242, 0.65);
  margin-bottom: 0.43rem;
  padding: 0.43rem;
}
.about-us__team-leader
  .about-us__team-leader-list
  .about-us__team-item:last-child {
  margin-bottom: 0;
}
.about-us__team-leader .about-us__team-leader-list .about-us__team-item img {
  width: 2.67rem;
  height: 2.67rem;
  border-radius: 50%;
}
.about-us__team-leader
  .about-us__team-leader-list
  .about-us__team-item
  .leader-name {
  font-size: 0.48rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.69rem;
  margin: 0.43rem 0 0.21rem;
}
.about-us__team-leader
  .about-us__team-leader-list
  .about-us__team-item
  .leader-position {
  font-size: 0.4rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.61rem;
}
.about-us__team-leader
  .about-us__team-leader-list
  .about-us__team-item
  .leader-position__desc {
  font-size: 0.37rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0.59rem;
  margin-top: 0.43rem;
}
