.home-brand-value {
  background: #f9fafc;
}
.home-brand-value .mb-container {
  padding: 0 0.43rem;
}
.home-brand-value .home-brand-value__title {
  font-size: 0.53rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
  text-align: center;
  padding: 0.85rem 0;
}
.home-brand-value .home-brand-value__pics {
  display: flex;
  justify-content: center;
  height: 6.72rem;
}
.home-brand-value .home-brand-value__pics .home-brand-value__pics-item {
  width: 1.04rem;
  height: 5.44rem;
  position: relative;
  margin-right: 0.19rem;
  border-radius: 0.11rem;
  overflow: hidden;
  perspective: 900;
  -webkit-perspective: 900;
}
.home-brand-value .home-brand-value__pics .home-brand-value__pics-item {
  width: 1.04rem;
  height: 5.44rem;
  position: relative;
  margin-right: 0.19rem;
  border-radius: 0.11rem;
  overflow: hidden;
  perspective: 900;
  -webkit-perspective: 900;
}
.home-brand-value
  .home-brand-value__pics
  .home-brand-value__pics-item:last-child {
  margin-right: 0;
}
.home-brand-value .home-brand-value__pics .home-brand-value__pics-item img {
  display: block;
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.34, 1.45, 0.7, 1);
  -webkit-transition-timing-function: cubic-bezier(0.34, 1.45, 0.7, 1);
}
.home-brand-value
  .home-brand-value__pics
  .home-brand-value__pics-item
  img:last-child {
  transform: rotateY(-180deg);
}
.home-brand-value .home-brand-value__desc {
  font-size: 0.37rem;
  font-weight: Normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  padding: 0.43rem 0 0.21rem;
}
.home-brand-value .home-brand-value__detail {
  font-size: 0.37rem;
  font-weight: Normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
}
