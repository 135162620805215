/* mobile端样式开始  */
.mobile-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.mobile-header .mb-container {
  width: 9.15rem;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 0.21rem;
  box-shadow: 0rem 0rem 0.53rem 0rem rgba(0, 0, 0, 0.06);
  margin-top: 0.32rem;
  padding: 0 0.43rem;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
}
.mobile-header i {
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  transition: all 0.35s ease-in-out;
}
.mobile-header .mb-container .header-root__top {
  padding: 0.45rem 0;
  height: 1.49rem;
}
.mobile-header .mb-container .header-root__left {
  width: 2.77rem;
  height: 0.59rem;
}
.mobile-header .mb-container .header-root__left img {
  display: block;
}
.mobile-header .mb-container .header-root__right {
  width: 0.53rem;
  height: 0.53rem;
}
.mobile-header .mb-container .header-root__right i {
  width: 100%;
  height: 100%;
}
.mobile-header .mb-container .header-root__bottom ul {
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}
.mobile-header .mb-container .header-root__bottom li {
  list-style-type: none;
  width: 100%;
}
.mobile-header .mb-container .header-root__bottom a {
  display: block;
  width: 100%;
  font-size: 0.4rem;
  text-align: center;
  line-height: 1.07rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.mobile-header .mb-container .header-root__bottom .active-nav {
  color: #377dff;
}
.header-cover {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}
/* mobile端样式结束 */
