.asymptote-home__container {
    /* width: 1920px; */
    min-width: 1900px;
    height: 100%;
}
.asymptote-home__container>img {
    width: auto;
    height: auto;
}

.asymptote-home__container>div {
    overflow: hidden;
}

/* header */
.asymptote-home__container .header__container {
    position: fixed;
    padding-top: 12px;
    width: 100%;
    z-index: 999;
}

.asymptote-home__container .header__content {
    width: 1440px;
    padding: 16px 32px;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 8px;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.06);
    margin: 0 auto;
}

.asymptote-home__container .header__navigation-list {
    gap: 32px;
    margin-left: auto;
    font-size: 15px;
    line-height: 1.6;
}

.asymptote-home__container .header__navigation-list>a {
    text-decoration: none;
    outline: none;
    color: rgba(0, 0, 0, 0.65);
}

/* footer */
.asymptote-home__container .footer__container {
    padding: 14px 0;
    font-size: 12px;
    line-height: 1.667;
    color: rgba(255, 255, 255, 0.65);
    background-color: #20232E;
}
.asymptote-home__container .footer__container>div {
    opacity: 0.6;
}