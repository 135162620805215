.about-us__photo-wall {
  background: #f9fafc;
}
.about-us__photo-wall .top {
  width: 10rem;
  height: 3.92rem;
  position: relative;
}
.about-us__photo-wall .top > div {
  font-size: 0.64rem;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  line-height: 0.75rem;
  position: absolute;
  left: 50%;
  top: 0.85rem;
  transform: translateX(-50%);
}
.about-us__photo-wall .bottom {
  background: #f9fafc;
}
.about-us__photo-wall .bottom .mb-container {
  position: relative;
  height: 6.05rem;
}

.about-us__photo-wall .bottom > .mb-container > div {
  position: absolute;
  border-radius: 0.11rem;
}

.about-us__photo-wall .mb-container .bottom-pic01 {
  width: 7.44rem;
  height: 3.97rem;
  left: 50%;
  transform: translateX(-50%);
  top: -1.47rem;
  z-index: 2;
  border-radius: 0.11rem;
}
.about-us__photo-wall .mb-container .bottom-pic02 {
  width: 3.23rem;
  height: 2.83rem;
  left: 0;
  bottom: 2rem;
  z-index: 2;
}
.about-us__photo-wall .mb-container .bottom-pic03 {
  width: 2.85rem;
  height: 2.27rem;
  right: 0;
  top: 0.96rem;
  z-index: 3;
}
.about-us__photo-wall .mb-container .bottom-pic04 {
  width: 2.27rem;
  height: 2.27rem;
  right: 2.37rem;
  bottom: 0.85rem;
  z-index: 1;
}
