.product-service__detail-item {
  background-color: #f9fafc;
}
.product-service__detail-item .mb-container {
  padding: 0.85rem 0.43rem 0;
}
.product-service__detail-item .mb-container .product-service__detail-title {
  font-size: 0.53rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
}
.product-service__detail-item .product-service__detail-title__desc {
  display: flex;
  align-items: center;
  height: 0.59rem;
  margin: 0.21rem 0 0.43rem;
}
.product-service__detail-item .product-service__detail-title__desc i {
  display: inline-block;
  width: 0.43rem;
  height: 0.43rem;
  margin-right: 0.21rem;
  background-color: transparent;
}
.product-service__detail-item .product-service__detail-title__desc .en-title {
  font-size: 0.37rem;
  font-weight: 400;
  color: #377dff;
  line-height: 0.59rem;
}
.product-service__detail-item
  .product-service__detail-title__desc
  .en-title:after {
  display: inline-block;
  margin: 0 0.21rem;
  content: "";
  height: 0.32rem;
  width: 0.01rem;
  background-color: rgba(0, 0, 0, 0.45);
}
.product-service__detail-item .product-service__detail-title__desc .desc {
  font-size: 0.37rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
}
.product-service__detail-item .mb-container .product-service__detail-item__pic {
  width: 100%;
  height: 5.01rem;
  position: relative;
}
.product-service__detail-item
  .product-service__detail-item__pic
  .product-service__detail-item__pic-title {
  position: absolute;
  left: 0.32rem;
  bottom: 0.32rem;
  z-index: 2;
  font-size: 0.32rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.53rem;
}
.product-service__detail-item
  .product-service__detail-item__pic
  .product-service__detail-item__pic-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(55, 125, 255, 0.65);
  border-radius: 0.21rem;
}
.product-service__detail-item
  .mb-container
  .product-service__detail-item__content {
  font-size: 0.37rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  padding: 0.43rem 0 0.85rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
