.header-root {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.header-root img {
  vertical-align: top;
}
.header-root .container {
  min-width: 1200px;
  margin-top: 12px;
  height: 56px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 0 32px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.header-root .header-root__left {
  width: 102px;
  height: 22px;
}
.header-root .header-root__right .header-root__right-nav {
  margin-right: 32px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
.header-root .header-root__right .active-nav {
  color: #377dff;
}
.header-root .header-root__right .header-root__right-nav:last-child {
  margin-right: 0;
}
