.jobs-page__banner .jobs-page__banner-main {
  width: 708px;
  height: 100%;
  padding-top: 200px;
  margin: 0 auto;
}

.jobs-page__banner .jobs-page__banner-main__top {
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

.jobs-page__banner .jobs-page__banner-main__top .jobs-page__banner-title {
  font-size: 48px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
  margin-bottom: 32px;
}

.jobs-page__banner .jobs-page__banner-main__top .jobs-page__banner-desc > p {
  font-size: 18px;
  line-height: 27px;
  padding: 0 30px;
}

.jobs-page__banner
  .jobs-page__banner-main__top
  .jobs-page__banner-desc
  > p:last-child {
  margin-top: 8px;
}

.jobs-page__banner .jobs-page__banner-main__top .jobs-page__banner-btn {
  display: inline-block;
  padding: 0 16px;
  text-align: center;
  line-height: 36px;
  background: #377dff;
  margin-top: 64px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  outline: none;
  border: none;
}

.jobs-page__banner
  .jobs-page__banner-main__bottom
  .jobs-page__banner-welfare__list {
  margin-bottom: 73px;
}

.jobs-page__banner
  .jobs-page__banner-main__bottom
  .jobs-page__banner-welfare__item {
  width: 50%;
  flex-shrink: 0;
  margin-bottom: 29px;
}

.jobs-page__banner
  .jobs-page__banner-main__bottom
  .jobs-page__banner-welfare__item:nth-child(odd) {
  text-align: left;
}

.jobs-page__banner .jobs-page__banner-welfare__item:nth-child(1),
.jobs-page__banner .jobs-page__banner-welfare__item:nth-child(5) {
  padding-left: 30px;
}

.jobs-page__banner .jobs-page__banner-welfare__item:nth-child(2),
.jobs-page__banner .jobs-page__banner-welfare__item:nth-child(6) {
  padding-right: 30px;
  margin-bottom: 0;
}

.jobs-page__banner
  .jobs-page__banner-main__bottom
  .jobs-page__banner-welfare__item:nth-child(even) {
  text-align: right;
}

.jobs-page__banner
  .jobs-page__banner-main__bottom
  .jobs-page__banner-welfare__item
  i {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: transparent;
}

.jobs-page__banner
  .jobs-page__banner-main__bottom
  .jobs-page__banner-welfare__item
  span {
  font-size: 18px;
  color: #ffffff;
  line-height: 26px;
  padding-left: 8px;
}
@media screen and (max-width: 1500px) {
  .jobs-page__banner .jobs-page__banner-main {
    width: 648px;
  }
  .jobs-page__banner .jobs-page__banner-main__top .jobs-page__banner-desc > p {
    padding: 0;
  }
  .jobs-page__banner
    .jobs-page__banner-main__bottom
    .jobs-page__banner-welfare__list {
    margin-bottom: 72px;
  }
}
