.about-us__page-banner .about-us__page-banner__top {
  width: 100%;
  height: 424px;
}
.about-us__page-banner .about-us__page-banner__top > span {
  font-size: 48px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
  line-height: 64px;
}
/* 顶部样式结束 */
.about-us__page-banner__bottom {
  background-color: #f9fafc;
}
.about-us__page-banner__bottom {
  padding: 64px 0;
}
.about-us__page-banner__bottom .about-us__page-banner__bottom-content {
  width: 1176px;
  margin: 0 auto;
  padding: 40px;
  height: 408px;
  border-radius: 8px;
  background-color: transparent;
}
.about-us__page-banner__bottom-content-left
  .about-us__page-banner__bottom-content-left__desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 28px;
  margin-right: 38px;
}
.about-us__page-banner__bottom-content-left
  .about-us__page-banner__bottom-content-left__list {
  text-align: center;
  margin-top: 21px;
}
.about-us__page-banner__bottom-content-left
  .about-us__page-banner__bottom-content-left__item {
  margin-right: 64px;
}
.about-us__page-banner__bottom-content-left
  .about-us__page-banner__bottom-content-left__item:last-child {
  margin-right: 0;
}
.about-us__page-banner__bottom-content-left
  .about-us__page-banner__bottom-content-left__item
  span:nth-child(1) {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #377dff;
  margin-bottom: 16px;
}
.about-us__page-banner__bottom-content-left
  .about-us__page-banner__bottom-content-left__item
  span:nth-child(2) {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
/* 左侧样式结束 */
.about-us__page-banner .about-us__page-banner__bottom-content-right {
  flex-shrink: 0;
  width: 426px;
  height: 328px;
}
.about-us__page-banner__bottom-content-right
  .about-us__page-banner__bottom-content-right_img
  img {
  width: 100%;
  height: 100%;
}
/* 右侧样式结束 */

@media screen and (max-width: 1500px) {
  .about-us__page-banner .about-us__page-banner__top {
    height: 364px;
  }
  .about-us__page-banner__bottom .container {
    padding: 64px 0;
  }
  .about-us__page-banner__bottom .about-us__page-banner__bottom-content {
    width: 983px;
    height: 408px;
    padding: 33px 16px 32px;
  }
  .about-us__page-banner__bottom-content-left
    .about-us__page-banner__bottom-content-left__desc {
    margin-right: 0;
    padding: 0 16px;
  }
  .about-us__page-banner .about-us__page-banner__bottom-content-right {
    width: 344px;
    height: 344px;
    padding: 0 16px;
    box-sizing: content-box;
  }
  .about-us__page-banner__bottom-content-left
    .about-us__page-banner__bottom-content-left__desc {
    font-size: 18px;
  }
  .about-us__page-banner__bottom-content-left
    .about-us__page-banner__bottom-content-left__list {
    margin-top: 43px;
  }
  .about-us__page-banner__bottom-content-left
    .about-us__page-banner__bottom-content-left__item
    span:nth-child(1) {
    margin-bottom: 12px;
  }
  .about-us__page-banner__bottom-content-left
    .about-us__page-banner__bottom-content-left__item
    span:nth-child(2) {
    font-size: 15px;
    line-height: 24px;
  }
}
