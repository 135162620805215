.product-services__partnership {
  background: #e9f0ff;
  padding-top: 132px;
  padding-bottom: 64px;
}

.product-services__partnership .product-services__partnership-list {
  padding-right: 27px;
  padding-left: 64px;
}

.product-services__partnership
  .product-services__partnership-list
  .product-services__partnership-item {
  margin: 24px 0;
}

.product-services__partnership
  .product-services__partnership-list
  .product-services__partnership-item__title {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 26px;
  margin-bottom: 8px;
}

.product-services__partnership
  .product-services__partnership-list
  .product-services__partnership-item__desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}

.product-services__partnership .product-services__partnership-pic {
  width: 602px;
  height: 366px;
}

.product-services__partnership .product-services__partnership-pic img {
  width: 100%;
  height: 100%;
  box-shadow: 3px 15px 33px 0px rgba(0, 0, 0, 0.07);
}
@media screen and (min-width: 768px) and (max-width: 1500px) {
  .product-services__partnership {
    padding-top: 100px;
    padding-bottom: 50px;
    background: #ffffff;
  }

  .product-services__partnership .home-panel__title {
    margin-bottom: 0 !important;
  }

  .product-services__partnership .product-services__partnership-content {
    padding-top: 32px;
  }

  .product-services__partnership .product-services__partnership-list {
    width: 50%;
    flex-shrink: 0;
    padding: 0 32px;
  }

  .product-services__partnership .product-services__partnership-pic {
    width: 50%;
    flex-shrink: 0;
    padding: 0 32px 2px;
  }

  .product-services__partnership
    .product-services__partnership-list
    .product-services__partnership-item {
    margin: 0 0 16px;
  }

  .product-services__partnership
    .product-services__partnership-list
    .product-services__partnership-item:last-child {
    margin-bottom: 0;
  }
}
