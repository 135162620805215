.home-product-services {
  background-color: #f9fafc;
}
.home-product-services .mb-container .home-product-services__title {
  font-size: 0.53rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
  padding: 0.85rem 0;
}
.home-product-services .home-product-services__pic {
  margin: 0 auto;
  width: 5.33rem;
  height: 7.09rem;
  position: relative;
}
.home-product-services .home-product-services__pic > div {
  width: 100%;
  height: 100%;
  box-shadow: 0.05rem 0.11rem 0.4rem 0rem rgba(55, 125, 255, 0.5);
  border-radius: 0.42rem;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  transform: rotateY(0);
  transition: all 0.8s cubic-bezier(0.34, 1.45, 0.7, 1) 0s;
}
.home-product-services .home-product-services__pic-front-content,
.home-product-services .home-product-services__pic-back-content {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #ffffff;
}
.home-product-services .home-product-services__pic-front-content img {
  width: 0.64rem;
  height: 0.64rem;
}
.home-product-services .home-product-services__pic-front-content h3 {
  font-size: 0.43rem;
  font-weight: 500;
  line-height: 0.64rem;
}
.home-product-services .home-product-services__pic-front-content p {
  width: 4.48rem;
  font-size: 0.37rem;
  line-height: 0.59rem;
}
.home-product-services .home-product-services__pic-back-content h3 {
  font-size: 0.43rem;
  font-weight: 500;
  line-height: 0.64rem;
  margin-bottom: 0.43rem;
}
.home-product-services .home-product-services__pic-back-content p {
  width: 4.69rem;
  font-size: 0.32rem;
  line-height: 0.53rem;
}
.home-product-services .home-product-services__pic-front-content-cover {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(55, 125, 255, 0.5);
  border-radius: 0.42rem;
}
.home-product-services .mb-container .home-product-services__pic-desc {
  width: 100%;
  padding: 0 0.43rem;
  text-align: center;
}
.home-product-services
  .home-product-services__pic-desc
  .home-product-services__pic-desc__ch-title {
  font-size: 0.48rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.69rem;
  padding: 0.43rem 0 0.21rem;
}
.home-product-services
  .home-product-services__pic-desc
  .home-product-services__pic-desc__en-title
  i {
  display: inline-block;
  width: 0.53rem;
  height: 0.53rem;
  vertical-align: middle;
  background-color: transparent;
  margin-right: 0.21rem;
}
.home-product-services
  .home-product-services__pic-desc
  .home-product-services__pic-desc__en-title
  span {
  font-size: 0.4rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.64rem;
}
.home-product-services
  .home-product-services__pic-desc
  .home-product-services__pic-desc__detail {
  font-size: 0.37rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
  padding-top: 0.43rem;
  min-height: 5.74rem;
}
.home-product-services .home-product-services__pic-btn {
  margin-top: 0.85rem;
  display: flex;
  justify-content: space-between;
}
.home-product-services .home-product-services__pic-btn div {
  width: 4.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.96rem;
  text-align: center;
  background: #ffffff;
  border-radius: 0.11rem;
  transition: all 0.3s;
}
.home-product-services .home-product-services__pic-btn div img {
  width: 0.53rem;
  height: 0.53rem;
  transition: all 0.3s;
}
