.result-overview__container {
    position: relative;
    padding: 112px 0;
    background-color: #2b2e3a;
}

.result-overview__mask {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/resultOverview/gradient.png") center/cover no-repeat;
}

.result-overview__main-container {
    width: 646px;
    margin: 0 auto;
}
.result-overview__main-row {
    gap: 112px;
    position: relative;
    z-index: 3;
}

.result-overview__main-row:not(:last-child) {
    margin-bottom: 70px;
}

.result-overview__main-row-item>h3 {
    color: #FBFDFE;
    font-size: 48px;
    line-height: 1.1667;
    font-weight: 700;
}

.result-overview__main-row-item>h4 {
    margin: 24px 0 8px 0;
    color: #E2E8F0;
    font-size: 24px;
    line-height: 1.33;
}

.result-overview__main-row-item>p {
    color: #728095;
    font-size: 14px;
    line-height: 1.571;
}

.result-overview__decoration-1 {
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/resultOverview/decoration1.png") center/cover no-repeat;
    width: 150px;
    height: 150px;
    position: absolute;
    top: 59px;
    left: 562px;
    border-radius: 50%;
}

.result-overview__decoration-1-gradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: radial-gradient(48.48% 50% at 50% 50%, transparent 0%, #2b2e3a 100%);
}

.result-overview__decoration-2 {
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/resultOverview/decoration2.png") center/cover no-repeat;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 436px;
    left: 1209px;
    border-radius: 50%;
}

.result-overview__decoration-2-gradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: radial-gradient(48.48% 50% at 50% 50%, transparent 0%, #2b2e3a 100%);
}

.result-overview__decoration-3 {
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/resultOverview/decoration3.png") center/cover no-repeat;
    width: 300px;
    height: 300px;
    position: absolute;
    top: 161px;
    left: 1770px;
    border-radius: 50%;
}

.result-overview__decoration-3-gradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: radial-gradient(48.48% 50% at 50% 50%, transparent 0%, #2b2e3a 100%);
}

.result-overview__circle-outer {
    width: 596px;
    height: 596px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.05);
    position: absolute;
    top: 13px;
    left: 1622px;
}

.result-overview__circle-inner {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.125);
}