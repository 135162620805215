.pager {
  display: flex;
  list-style: none;
  height: 32px;
  border-radius: 0.25rem;
}
.pager li {
  min-width: 32px;
  height: 32px;
  border-radius: 2px;
  margin-right: 8px;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
  color: #4e5969;
  font-weight: 400;
  list-style: none;
  cursor: pointer;
}
.pager li:first-child, .pager li:last-child{
  display: flex;
  justify-content: center;
  align-items: center;
}
.pager li:first-child i, .pager li:last-child i{
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: transparent;
}
.pager li.active {
  color: #165dff;
  background: #e8f3ff;
}
