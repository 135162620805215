.about-us__banner {
  background-color: #f9fafc;
}
.about-us__banner .mb-container {
  margin-top: 0.85rem;
}
.about-us__banner-content {
  width: 10rem;
  height: 5.12rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us__banner-content span {
  font-size: 0.64rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.75rem;
}
.about-us__banner .about-us__company-info {
  padding: 0.43rem;
  background-color: transparent;
}
.about-us__banner .about-us__company-info .about-us__company-info__desc {
  font-size: 0.37rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.59rem;
}
.about-us__banner .about-us__company-info .about-us__company-info__pic {
  width: 8.27rem;
  height: 4.13rem;
  margin: 0.43rem 0;
}
.about-us__banner .about-us__company-info .about-us__company-info__list {
  display: flex;
  justify-content: space-between;
}
.about-us__banner
  .about-us__company-info__list
  .about-us__company-info__item
  div {
  font-size: 0.53rem;
  font-weight: 500;
  color: #377dff;
  line-height: 0.75rem;
}
.about-us__banner
  .about-us__company-info__list
  .about-us__company-info__item
  p {
  font-size: 0.32rem;
  font-weight: Normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
}
