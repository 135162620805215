.service-range__container {
    position: relative;
    overflow: hidden;
    padding: 112px 0;
    background: #F8FBFF;
}

.service-range__container h3 {
    margin: 0;
    color: #1D2129;
    font-size: 32px;
    line-height: 1.25;
    font-weight: 800;
}

.service-range__container h4 {
    margin: 24px 0 48px 0;
    color: #1D2129;
    font-size: 14px;
    line-height: 1.571;
}

.service-range__card-list-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 48px;
    column-gap: 24px;
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.service-range__card {
    padding: 32px 32px 48px 32px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
}

.service-range__card-icon-container {
    width: 64px;
    height: 64px;
    background-color: #2b6cb0;
    border-radius: 50%;
}

.service-range__card-title {
    margin: 32px 0 11px 0;
    color: var(---90, #1A202C);
    font-size: 24px;
    line-height: 1.417;
    font-weight: 800;
}

.service-range__card-content {
    color: var(---60, #728095);
    font-size: 14px;
    line-height: 1.571;
}

.service-range__mask {
    position: absolute;
    top: -9px;
    /* z-index: -1; */
    left: 40.573%;
    width: 1964px;
    height: 1020px;
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/sectionTwo/background.png") 64px/100% no-repeat;
}

.service-range__mask-gradient {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 250px 8px rgba(255, 255, 255, 0.63);
}
