.product-service__detail-content .product-service__detail-info {
  width: 50%;
  padding: 115px;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.06);
}
.product-service__detail-content
  .product-service__detail-info
  .product-service__detail-ch__title {
  font-size: 32px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 40px;
}
.product-service__detail-content
  .product-service__detail-info
  .product-service__detail-en__title {
  font-size: 18px;
  color: #377dff;
  font-weight: 500;
  line-height: 28px;
}
.product-service__detail-content
  .product-service__detail-info
  .product-service__detail-desc {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin: 32px 0;
}
.product-service__detail-content
  .product-service__detail-info
  .product-service__detail-ch__title,
.product-service__detail
  .product-service__detail-info
  .product-service__detail-desc {
  margin-bottom: 32px;
}
.product-service__detail-content
  .product-service__detail-info
  .product-service__detail-centent {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  padding: 0 13px;
  text-align: left;
}
/* 产品文本样式结束 */

/* 图片样式开始 */
.product-service__detail-content .product-service__detail-pic__info {
  width: 50%;
  height: 454px;
  flex-shrink: 0;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-service__detail-content
  .product-service__detail-pic__info
  .product-service__detail-pic_content-title {
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
  line-height: 40px;
  position: relative;
  z-index: 2;
  margin-bottom: 32px;
  opacity: 0;
  transition: all 0.2s;
}
.product-service__detail-content
  .product-service__detail-pic__info
  .product-service__detail-pic_content-title__desc {
  font-size: 15px;
  color: #ffffff;
  line-height: 30px;
  padding: 0 128px;
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: all 0.2s;
}
.product-service__detail-pic__info:hover
  .product-service__detail-pic_content-title,
.product-service__detail-pic__info:hover
  .product-service__detail-pic_content-title__desc {
  opacity: 1;
}
.product-service__detail-content .product-service__detail-pic_cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 125, 255, 0.65);
  border-radius: 12px;
  box-shadow: 2px 4px 15px 0px rgba(55, 125, 255, 0.5);
}
.product-service__detail-content .product-service__detail-pic__list {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
}
.product-service__detail-content .product-service__detail-pic__list span {
  display: block;
  width: 4px;
  height: 64px;
  background: #c4c4c4;
  border-radius: 60px;
  margin-bottom: 8px;
}
.product-service__detail-content .product-service__detail-pic__list span:last-child {
  margin-bottom: 0;
}
/* 图片样式结束 */
@media screen and (max-width: 1440px) {
  .product-service__detail-content .product-service__detail-info {
    padding: 87px 64px;
  }
  .product-service__detail-content
    .product-service__detail-info
    .product-service__detail-ch__title,
    .product-service__detail-content
    .product-service__detail-info
    .product-service__detail-desc {
    margin-bottom: 24px;
  }
  .product-service__detail-pic_content {
    display: none;
  }
  .product-service__detail-content
    .product-service__detail-pic__info
    .product-service__detail-pic_content-title {
    margin-bottom: 24px;
  }
  .product-service__detail-content
    .product-service__detail-pic__info
    .product-service__detail-pic_content-title__desc {
    padding: 0 64px;
  }
}
