.product-services__banner {
  background: #f0f4fc;
  padding-top: 131px;
  position: relative;
}
.product-services__banner .product-services__banner-title {
  font-size: 48px;
  line-height: 64px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 32px;
  color: rgba(0, 0, 0, 0.65);
}
.product-services__banner .product-services__banner-title__desc {
  width: 528px;
  height: 48px;
  font-size: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
.product-services__banner .product-services__banner-content {
  width: 792px;
  height: 578px;
  margin-top: 45.5px;
  background-size: 792px 578px;
  background-color: transparent;
  position: relative;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item-position {
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #a4c3fc;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item-position:nth-child(1) {
  left: 48px;
  top: 0;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item-position:nth-child(2) {
  right: -2px;
  top: 168px;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item-position:nth-child(3) {
  left: -25px;
  bottom: 194px;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item-position:nth-child(4) {
  right: 111px;
  bottom: -32px;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #a4c3fc;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item
  img {
  position: absolute;
  left: 0;
  top: -30px;
  cursor: pointer;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__item
  span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__center {
  width: 318px;
  height: 318px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__center-item {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -72px;
  text-align: center;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__center-info
  > p {
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__center-info
  > div
  > span {
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.85);
  padding: 0 8px;
  position: relative;
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__center-info
  > div
  > span::after {
  position: absolute;
  right: 0;
  top: 2px;
  display: block;
  content: "";
  width: 1px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.85);
}
.product-services__banner
  .product-services__banner-content
  .product-services__banner-content__center-info
  > div
  > span:last-child:after {
  display: none;
}
.product-services__banner .product-services__banner-more {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 26px;
  padding: 0 16px;
  text-align: center;
  line-height: 36px;
  background: #377dff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  outline: none;
  border: none;
}

@media screen and (max-width: 1500px) {
  .product-services__banner {
    padding-top: 132px;
  }
  .product-services__banner .product-services__banner-title {
    margin-bottom: 16px;
  }
  .product-services__banner .product-services__banner-content {
    width: 632px;
    height: 452px;
    background-size: 632px 452px;
  }
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__item-position {
    width: 80px;
    height: 80px;
  }
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__item,
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__item
    img {
    width: 100%;
    height: 100%;
  }

  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__item-position:nth-child(2) {
    right: -44px;
    top: 138px;
  }
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__item-position:nth-child(3) {
    left: -48px;
    bottom: 135px;
  }
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__item-position:nth-child(4) {
    right: 31px;
    bottom: -8px;
  }
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__center {
    width: 306px;
    height: 306px;
  }
  .product-services__banner
    .product-services__banner-content
    .product-services__banner-content__center-item {
    margin-top: -97px;
  }
}
