/* certificates */
.asymptote-home__container .certificates__container {
    position: relative;
    background: #2B2E3A;
    padding: 112px 0;
}

.asymptote-home__container .certificates__mask {
    top: 0;
    left: 0;
    position: absolute;
    background: url("https://asymptote-official-website.oss-cn-shenzhen.aliyuncs.com/website_images/newHome/certificates/mask.png") center/cover no-repeat;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.asymptote-home__container .certificates__main {
    width: 1124px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 12px;
    line-height: 1.667;
}

.asymptote-home__container .certificates__main>h2 {
    color: #fff;
    font-size: 32px;
    line-height: 1.25;
    font-weight: 700;
}

.asymptote-home__container .certificates__main>p {
    color: #CCD5DF;
    font-size: 14px;
    line-height: 1.571;
    margin-top: 24px;
    letter-spacing: 0.5px;
}

.asymptote-home__container .certificates__list-row {
    gap: 20px;
}

.asymptote-home__container .certificates__list-row:first-child {
    margin: 52px 0 48px 0;
}

.asymptote-home__container .certificates__item {
    position: relative;
    /* border: 1px solid transparent; */
    border-radius: 16px;
    text-align: center;
}

.asymptote-home__container .certificates__item::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(to bottom, rgba(134, 144, 156, 0), rgba(134, 144, 156, 1));
    z-index: -2;
    border-radius: 16px;
}

.asymptote-home__container .certificates__item::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #2b2e3a;
    z-index: -1;
    border-radius: 16px;
}

.asymptote-home__container .certificates__item>p {
    position: absolute;
    width: 100%;
    padding: 0 20px;
    bottom: 20px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    line-height: 1.667;
    letter-spacing: 0.5px;
}