.join-us {
  background-color: #f9fafc;
  padding: 0.21rem 0 0.85rem;
}
.join-us .mb-container {
  text-align: center;
  padding: 0.43rem 0.32rem 0;
  border-radius: 0.21rem;
}
.join-us .join-us__title {
  font-size: 0.53rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 0.75rem;
}
.join-us .join-us__title:after {
  content: "";
  display: block;
  width: 0.64rem;
  height: 0.08rem;
  background-color: #ffffff;
  border-radius: 0.21rem;
  margin: 0.32rem auto;
}
.join-us .join-us__desc {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
}
.join-us .join-us__contact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.32rem 0 0.64rem;
}
.join-us .join-us__contact span {
  font-size: 0.37rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.59rem;
}
.join-us .join-us__contact i {
  display: inline-block;
  width: 0.53rem;
  height: 0.53rem;
  background-color: transparent;
}
.join-us .join-us__follow {
  font-size: 0.48rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.69rem;
  margin-bottom: 0.43rem;
}
.join-us .mb-container .join-us__list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.join-us .mb-container .join-us__list .join-us__item {
  display: flex;
  width: 8.29rem;
  height: 2.67rem;
  background: #ffffff;
  border-radius: 0.11rem;
  padding: 0.32rem;
  margin-bottom: 0.32rem;
}
.join-us .mb-container .join-us__list .join-us__item > div {
  text-align: left;
  margin-left: 0.32rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.join-us .mb-container .join-us__list .join-us__item img {
  width: 2.03rem;
  height: 2.03rem;
  border-radius: 0.11rem;
}
.join-us .mb-container .join-us__list .join-us__item .join-us-item__name {
  font-size: 0.37rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.59rem;
}
.join-us .mb-container .join-us__list .join-us__item .join-us-item__desc {
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
  -webkit-line-clamp: 2;
}
