.jobs-banner__wrapper {
  width: 100%;
  height: 13.09rem;
  padding-top: 2.67rem;
}
.jobs-banner__wrapper .jobs-banner__wrapper-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.61rem;
}
.jobs-banner__wrapper .jobs-banner__wrapper-main .jobs-banner__title {
  font-size: 0.64rem;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.75rem;
  margin-bottom: 0.21rem;
}
.jobs-banner__wrapper .jobs-banner__wrapper-main .jobs-banner__desc {
  width: 8.29rem;
  font-size: 0.32rem;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0.53rem;
}
.jobs-banner__wrapper .jobs-banner__wrapper-main .jobs-banner__btn {
  display: block;
  width: 3.09rem;
  line-height: 0.96rem;
  background: #377dff;
  border-radius: 0.11rem;
  font-size: 0.37rem;
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
  margin: 0.85rem 0;
}
.jobs-banner__wrapper
  .jobs-banner__wrapper-main
  .jobs-banner__list {
    margin-top: 2.6rem;
  }
.jobs-banner__wrapper
  .jobs-banner__wrapper-main
  .jobs-banner__list
  .jobs-banner__item {
  margin-bottom: 0.32rem;
  display: flex;
  align-items: center;
}
.jobs-banner__wrapper
  .jobs-banner__wrapper-main
  .jobs-banner__list
  .jobs-banner__item:last-child {
  margin-bottom: 0;
}
.jobs-banner__wrapper
  .jobs-banner__wrapper-main
  .jobs-banner__list
  .jobs-banner__item
  i {
  width: 0.53rem;
  height: 0.53rem;
  margin-right: 0.11rem;
  background-color: transparent;
}
.jobs-banner__wrapper
  .jobs-banner__wrapper-main
  .jobs-banner__list
  .jobs-banner__item
  span {
  font-size: 0.37rem;
  font-weight: normal;
  color: #ffffff;
  line-height: 0.59rem;
}
